/** frontend/src/Referrals.js */

import React, { useEffect, useState } from 'react';
import './Referrals.css';

const authServiceUrl = process.env.REACT_APP_AUTH_SERVICE_URL;

function Referrals({ user, gameBotUrl, showNotification }) {
  const [referralCount, setReferralCount] = useState(0);

  useEffect(() => {
    // Fetch the number of referrals for the user
    const fetchReferrals = async () => {
      try {
        const response = await fetch(`${authServiceUrl}/referrals/${user.telegram_id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch referral count');
        }
        const data = await response.json();
        setReferralCount(data.referral_count || 0);
      } catch (error) {
        console.error('Error fetching referrals:', error);
        showNotification('Something went wrong fetching your referrals', 'error');
      }
    };

    fetchReferrals();
  }, [user, showNotification]);

  const handleSendToFriend = () => {
    const tg = window.Telegram.WebApp;
    const referralLink = `https://t.me/${gameBotUrl}?startapp=${user.telegram_id}`;
    const message = `Yo, join our crew and become a BRO! Hit this link`;
    const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(message)}`;
    tg.openTelegramLink(shareUrl);
  };

  const handleCopyReferralLink = () => {
    const referralLink = `https://t.me/${gameBotUrl}?startapp=${user.telegram_id}`;
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        showNotification('Link copied to your clipboard!', 'success');
      })
      .catch((err) => {
        console.error('Error copying link:', err);
        showNotification('Failed to copy the link.', 'error');
      });
  };

  return (
    <div className="referrals-container">
      <h2>Invite Your Homies and Become a BRO!</h2>
      <p>Your Bros: {referralCount}</p>
      <div className="referrals-buttons">
        <button className="referral-button" onClick={handleSendToFriend}>
          Send BRO
        </button>
        <button className="referral-button" onClick={handleCopyReferralLink}>
          Copy Link
        </button>
      </div>
    </div>
  );
}

export default Referrals;
