// frontend/src/App.js

import React, { useEffect, useState } from 'react';
import './App.css';
import Leaderboard from './Leaderboard';
import Tasks from './Tasks';
import Referrals from './Referrals';

const authServiceUrl = process.env.REACT_APP_AUTH_SERVICE_URL;
const gameServiceUrl = process.env.REACT_APP_GAME_SERVICE_URL;
const gameBotUrl = process.env.REACT_APP_GAME_BOT_URL;

function App() {
  const [user, setUser] = useState(null);
  const [referralId, setReferralId] = useState(null);
  const [activePage, setActivePage] = useState('GM');
  const [totalScore, setTotalScore] = useState(0);
  const [totalObjects, setTotalObjects] = useState(0);
  const [referralsCount, setReferralsCount] = useState(0);
  const [gamesLeft, setGamesLeft] = useState(0);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [notificationType, setNotificationType] = useState(null);

  useEffect(() => {
    // Initialize Telegram WebApp and fetch user data
    const tg = window.Telegram.WebApp;
    tg.ready();

    const initData = tg.initData || '';
    let startParam = tg.initDataUnsafe?.start_param;

    if (!startParam) {
      const urlParams = new URLSearchParams(window.location.search);
      startParam = urlParams.get('tgWebAppStartParam');
    }

    if (startParam) {
      setReferralId(startParam);
    }

    let body = `initData=${encodeURIComponent(initData)}`;
    if (startParam) {
      body += `&referral_id=${encodeURIComponent(startParam)}`;
    }

    fetch(`${authServiceUrl}/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Authentication failed');
        }
        return response.json();
      })
      .then((data) => {
        setUser(data);
        fetchUserStats(data.telegram_id);
        fetchGamesLeft(data.telegram_id);
        fetchReferralsCount(data.telegram_id);
      })
      .catch((error) => {
        console.error('Authentication error:', error);
        showNotification('Authentication failed. Please try again.', 'error');
      });
  }, []);

  /**
   * Fetches user stats from game_service.
   */
  const fetchUserStats = (userId) => {
    fetch(`${gameServiceUrl}/stats/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setTotalScore(data.total_score || 0);
        setTotalObjects(data.total_objects || 0);
      })
      .catch((error) => {
        console.error('Error fetching user stats:', error);
        showNotification('Failed to fetch user stats.', 'error');
      });
  };

  /**
   * Fetches the number of referrals for the user.
   */
  const fetchReferralsCount = (userId) => {
    fetch(`${authServiceUrl}/referrals/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setReferralsCount(data.referral_count || 0);
      })
      .catch((error) => {
        console.error('Error fetching referral count:', error);
        showNotification('Failed to fetch referrals.', 'error');
      });
  };

  /**
   * Fetches the number of remaining games for the user.
   */
  const fetchGamesLeft = (userId) => {
    fetch(`${gameServiceUrl}/games_left/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setGamesLeft(data.games_left || 0);
      })
      .catch((error) => {
        console.error('Error fetching games left:', error);
        showNotification('Failed to fetch games left.', 'error');
      });
  };

  /**
   * Shows a notification to the user.
   */
  const showNotification = (message, type = 'info') => {
    setNotificationMessage(message);
    setNotificationType(type);
    // Hide the notification after 3 seconds
    setTimeout(() => {
      setNotificationMessage(null);
      setNotificationType(null);
    }, 3000);
  };

  /**
   * Handles the GM button click and creates a new game.
   */
  const handleGMClick = () => {
    if (gamesLeft > 0) {
      // Create a new game
      fetch(`${gameServiceUrl}/onegame`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: user.telegram_id,
          objects_collected: 1,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to create game');
          }
          return response.json();
        })
        .then((data) => {
          showNotification('GJ BRO!', 'success');
          // Update user stats
          fetchUserStats(user.telegram_id);
          fetchGamesLeft(user.telegram_id);
        })
        .catch((error) => {
          console.error('Error creating game:', error);
          showNotification('Failed to create game.', 'error');
        });
    } else {
      showNotification('No more games left for you.', 'error');
    }
  };

  return (
    <div className="App">
      {user ? (
        <main className="content">
          {/* Display notifications */}
          {notificationMessage && (
            <div className={`notification ${notificationType}`}>
              {notificationMessage}
            </div>
          )}

          {activePage === 'GM' && (
            <div className="main-screen">
              <button
                className={`gm-button ${gamesLeft > 0 ? '' : 'inactive'}`}
                onClick={handleGMClick}
                disabled={gamesLeft <= 0}
              >
                GM
              </button>

              <div className="data-lines">
                <div className="data-line">$G = GM * BRO</div>
                <div className="data-line">GM: {totalObjects} | BRO: {referralsCount}</div>
                <div className="data-line">YOUR $G: {totalObjects * referralsCount}</div>
              </div>
            </div>
          )}
          {activePage === 'DO' && (
            <Tasks
              user={user}
              showNotification={showNotification}
              fetchUserStats={fetchUserStats}
              fetchGamesLeft={fetchGamesLeft}
            />
          )}
          {activePage === 'BRO' && (
            <Referrals
              user={user}
              gameBotUrl={gameBotUrl}
              showNotification={showNotification}
            />
          )}
          {activePage === 'leaders' && <Leaderboard user={user} />}
        </main>
      ) : (
        <h1>Loading...</h1>
      )}

      {/* Bottom menu */}
      {user && (
        <div className="bottom-menu">
          <button
            className={activePage === 'GM' ? 'active' : ''}
            onClick={() => setActivePage('GM')}
          >
            GM
          </button>
          <button
            className={activePage === 'DO' ? 'active' : ''}
            onClick={() => setActivePage('DO')}
          >
            DO
          </button>
          <button
            className={activePage === 'BRO' ? 'active' : ''}
            onClick={() => setActivePage('BRO')}
          >
            BRO
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
