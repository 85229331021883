// frontend/src/Tasks.js

import React, { useEffect, useState } from 'react';
import './Tasks.css';

// Получаем URL сервиса заданий из переменных окружения
const tasksServiceUrl = process.env.REACT_APP_TASKS_SERVICE_URL;

function Tasks({ user, showNotification, fetchUserStats, fetchGamesLeft }) { // Добавлен fetchGamesLeft
  const [tasks, setTasks] = useState([]);
  const [loadingTaskId, setLoadingTaskId] = useState(null);

  useEffect(() => {
    // Функция для получения заданий с сервера
    const fetchTasks = async () => {
      try {
        const response = await fetch(`${tasksServiceUrl}/user/${user.telegram_id}`);
        if (!response.ok) {
          throw new Error('Error while receiving tasks');
        }
        const data = await response.json();
        // Фильтруем только 'one-time' задания
        const oneTimeTasks = data.filter(task => task.time_type === 'one-time');
        // Сортируем задания: сначала невыполненные, потом выполненные
        oneTimeTasks.sort((a, b) => {
          if (a.is_completed === b.is_completed) {
            return a.id - b.id;
          }
          return a.is_completed ? 1 : -1;
        });
        setTasks(oneTimeTasks);
      } catch (error) {
        console.error('Ошибка при получении заданий:', error);
        showNotification('ERROR', 'error');
      }
    };

    fetchTasks();
  }, [user, showNotification]);

  // Функция для проверки выполнения задания
  const validateTask = async (taskId) => {
    try {
      const response = await fetch(`${tasksServiceUrl}/validate/${taskId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: user.telegram_id }),
      });
      if (!response.ok) {
        throw new Error('Error while validating task');
      }
      const data = await response.json();

      // Обновляем статус задания в списке
      setTasks((prevTasks) => {
        const updatedTasks = prevTasks.map((task) =>
          task.id === taskId ? { ...task, is_completed: data.is_completed } : task
        );
        return updatedTasks;
      });

      if (data.is_completed) {
        // Обновляем статистику пользователя
        fetchUserStats(user.telegram_id);
        fetchGamesLeft(user.telegram_id); // Обновление gamesLeft
        showNotification('GJ BRO!', 'success');
      } else {
        showNotification('Not yet BRO!', 'error');
      }
    } catch (error) {
      console.error('Error during task validation:', error);
      showNotification('Error during task validation.', 'error');
    } finally {
      setLoadingTaskId(null);
    }
  };

  const startTask = (task) => {
    // Проверяем, является ли ссылка на Telegram
    if (task.link.includes('t.me') || task.link.startsWith('tg://')) {
      // Перенаправляем пользователя на ссылку
      window.location.href = task.link;
    } else {
      // Для остальных ссылок используем window.open
      window.open(task.link, '_blank');
    }

    // Устанавливаем задержку перед проверкой задания
    setLoadingTaskId(task.id);
    const delay = ['subscribe_telegram', 'connect_ton_wallet', 'donate_stars', 'donate_ton', 'vote_channel'].includes(task.sub_type) ? 5000 : 10000;
    setTimeout(() => {
      validateTask(task.id);
    }, delay);
  };

  const handleTaskButtonClick = (task) => {
    if (task.main_type === 'count') {
      validateTask(task.id);
    } else {
      startTask(task);
    }
  };

  return (
    <div className="tasks-container">
      <h2>Just do it.</h2>

      {/* Список заданий с прокруткой */}
      <div className="tasks-list">
        {tasks.length === 0 ? (
          <p>There's nothing, come back later.</p>
        ) : (
          tasks.map((task) => (
            <div key={task.id} className={`task-item ${task.is_completed ? 'completed' : 'active'}`}>
              <div className="task-info">
                <p className="task-name">{task.name}</p>
                <p className="task-points">+ {task.points} GM</p>
              </div>
              <button
                className={`task-button ${task.is_completed ? 'completed' : 'active'}`}
                onClick={() => handleTaskButtonClick(task)}
                disabled={task.is_completed || loadingTaskId === task.id}
              >
                {task.is_completed
                  ? 'Done'
                  : loadingTaskId === task.id
                  ? 'ZZzzz...'
                  : task.main_type === 'count'
                  ? 'Peep it'
                  : 'Kick off'}
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Tasks;
