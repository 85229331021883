// src/Leaderboard.js

import React, { useState, useEffect } from 'react';
import './Leaderboard.css';
import { useTranslation } from 'react-i18next';

const gameServiceUrl = process.env.REACT_APP_GAME_SERVICE_URL;

/**
 * Компонент Leaderboard отображает рейтинги пользователей по различным критериям.
 * @param {Object} user - Объект текущего пользователя.
 */
function Leaderboard({ user }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('total_score');
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [userRanking, setUserRanking] = useState(null);

  useEffect(() => {
    if (user) {
      fetchLeaderboardData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, user]);

  /**
   * Получает данные рейтинга с сервера в зависимости от активной вкладки.
   */
  const fetchLeaderboardData = () => {
    let url = '';
    if (activeTab === 'total_score') {
      url = `${gameServiceUrl}/ranking/total_score`;
    } else {
      url = `${gameServiceUrl}/ranking/${activeTab}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // Добавляем поле rank каждому элементу
        data.forEach((item, index) => {
          item.rank = index + 1;
        });

        // Находим текущего пользователя в рейтинге
        const userIndex = data.findIndex((item) => item.user_id === user.telegram_id);
        let currentUserRanking = null;

        if (userIndex !== -1) {
          const userData = data[userIndex];
          currentUserRanking = {
            ...userData,
          };

          // Если текущий пользователь на 1 месте, удаляем его из списка, чтобы избежать дублирования
          if (userData.rank === 1) {
            data.splice(userIndex, 1);
          }
        } else {
          // Пользователь не в топе
          currentUserRanking = {
            rank: t('Not_in_top'),
            user_id: user.telegram_id,
            username: user.username || user.telegram_id,
            total_score: '-',
            best_score: '-',
          };
        }

        setUserRanking(currentUserRanking);
        setLeaderboardData(data);
      })
      .catch((error) => {
        console.error('Ошибка при получении рейтинга:', error);
      });
  };

  /**
   * Рендерит строку рейтинга пользователя.
   * @param {Object} userData - Данные пользователя.
   */
  const renderLeaderboardRow = (userData) => {
    return (
      <tr key={userData.user_id}>
        <td>{userData.rank}</td>
        <td>{userData.username || userData.user_id}</td>
        {activeTab === 'total_score' ? (
          <td>{userData.total_score}</td>
        ) : (
          <td>{userData.best_score}</td>
        )}
      </tr>
    );
  };

  return (
    <div className="leaderboard-container">
      <div className="leaderboard-tabs">
        <button
          onClick={() => setActiveTab('total_score')}
          className={activeTab === 'total_score' ? 'active' : ''}
        >
          {t('Total_Score')}
        </button>
        <button
          onClick={() => setActiveTab('day')}
          className={activeTab === 'day' ? 'active' : ''}
        >
          {t('Daily_Best')}
        </button>
        <button
          onClick={() => setActiveTab('week')}
          className={activeTab === 'week' ? 'active' : ''}
        >
          {t('Weekly_Best')}
        </button>
        <button
          onClick={() => setActiveTab('month')}
          className={activeTab === 'month' ? 'active' : ''}
        >
          {t('Monthly_Best')}
        </button>
      </div>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>{t('Rank')}</th>
            <th>{t('User')}</th>
            {activeTab === 'total_score' ? (
              <th>{t('Total_Score')}</th>
            ) : (
              <th>{t('Best_Score')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {userRanking && (
            <tr className="current-user-row">
              <td>{userRanking.rank}</td>
              <td>{userRanking.username || userRanking.user_id}</td>
              {activeTab === 'total_score' ? (
                <td>{userRanking.total_score}</td>
              ) : (
                <td>{userRanking.best_score}</td>
              )}
            </tr>
          )}
          {leaderboardData.map((userData) => renderLeaderboardRow(userData))}
        </tbody>
      </table>
    </div>
  );
}

export default Leaderboard;
